// Import all plugins
import * as bootstrap from 'bootstrap';

const skillsConfig = [
  { id: 'javascript', value: '85%' },
  { id: 'angular2', value: '85%' },
  { id: 'ngrx', value: '80%' },
  { id: 'rxjs', value: '80%' },
  { id: 'vue', value: '70%' },
  { id: 'typescript', value: '85%' },
  { id: 'html', value: '90%' },
  { id: 'css', value: '95%' },
  { id: 'python', value: '55%' }
];

const skillsContainer = null;

window.animateBars = () => {
  let doc = document.getElementsByClassName('progress-bar');
  let customValue;
  for (let i = 0; i < doc.length; i++) {
    customValue = skillsConfig.find(item => doc[i].id === item.id).value;
    doc[i].style.width = customValue;
    doc[i].innerHTML = customValue;
  }
};

window.resetBars = () => {
  let doc = document.getElementsByClassName('progress-bar');
  let customValue = '0%';
  for (let i = 0; i < doc.length; i++) {
    doc[i].style.width = customValue;
    doc[i].innerHTML = customValue;
  }
};

window.isInViewPort = (element) => {
  var position = element.getBoundingClientRect();

	if(position.top >= 0 && position.bottom <= window.innerHeight) {
		return true
	}

	else if(position.top < window.innerHeight && position.bottom >= 0) {
		return true;
	}

  else {
    return false;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.skillsContainer = document.querySelector('#skills-bars');
});

document.addEventListener('scroll', (e) => {
  let isVisible = window.isInViewPort(window.skillsContainer);
  
  if(isVisible) {
    window.animateBars();
  } 
});